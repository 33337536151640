import * as model from '../model.js';
//for polyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import loginView from '../views/loginView.js';

//Controller for login page
const controlLogin = async function (newLogin) {
  try {
    // Render the spinner while waiting for authentication
    loginView.renderSpinner();

    // 2) Authenticate with jwt
    await model.login(newLogin);
  } catch (err) {
    console.log(err);
    loginView.renderError(); //display login error message if authentication fails
  }
};

//Controller for forgot password
const controlForgotPassword = async function (user) {
  try {
    // 2) Call model to trigger API to request for password reset link to be sent to user email
    loginView.renderSpinner();
    await model.forgotPassword(user);
    loginView.renderMessage();
  } catch (err) {
    console.log(err);
    if (err.message.includes('400')) {
      loginView.renderError('Email format is incorrect');
    } else {
      loginView.renderError(err.message.split('(')[0]); //display wrong email error message if user does not exist. Split removes the error code
    }
  }
};

// Initialization of the Controller functions to subscribe to View Event Listener
const init = function () {
  loginView.addHandlerLogin(controlLogin);
  loginView.addHandlerForgotPassword(controlForgotPassword);
  loginView.addHandlerReceiveToken();
  loginView.addHandlerRemoveErrorMessage();
  loginView.addHandlerTogglePassword();
};

init();
